// src/utils.js
export const calculateMissedPrayers = (startDate, endDate, times) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
  var selectedTimes = JSON.parse(JSON.stringify(times));
  if (selectedTimes.length === 0) {
    selectedTimes = ["sabah", "ogle", "ikindi", "aksam", "yatsi", "vitir"];
  }
  const prayers = {
    sabah: { id:0, missed: selectedTimes.includes("sabah") ? days : 0, completed: 0 },
    ogle: {id:1, missed: selectedTimes.includes("ogle") ? days : 0, completed: 0 },
    ikindi: {id:2, missed: selectedTimes.includes("ikindi") ? days : 0, completed: 0 },
    aksam: {id:3, missed: selectedTimes.includes("aksam") ? days : 0, completed: 0 },
    yatsi: {id:4, missed: selectedTimes.includes("yatsi") ? days : 0, completed: 0 },
    vitir: {id:5, missed: selectedTimes.includes("vitir") ? days : 0, completed: 0 },
  };
  return prayers;
};


export const dateFormater = (date) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(date).toLocaleDateString('tr-TR', options);
}

export const timestampToDate = (timestamp) => {
  return new Date(timestamp.seconds * 1000);
}