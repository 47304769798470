<template>
  <div class="body-container">
    <div v-if="!user" style="padding: 40px 30px;">
      <div v-if="!register">
        <div style="text-align: center;">
          <img src="./assets/logo.svg" alt="logo" />
          <h1>Kaza Namazı</h1>
          <h3>Giriş Yap</h3>
        </div>
        <v-divider class="pb-6"></v-divider>
        <div class="text-field">
          <span style="font-size: 14px;">E-posta:</span>
          <v-text-field class="input-field" v-model="registerData.email" type="e-mail" variant="outlined" persistent-hint></v-text-field>
        </div>
        <div class="text-field">
          <span style="font-size: 14px;">Şifre:</span>
          <v-text-field class="input-field" v-model="registerData.password" type="password" variant="outlined" persistent-hint></v-text-field>
        </div>
        <div class="register-button pb-10">
          <v-btn class="text-none" @click="loginUser" block color="#FDCC34" size="x-large" variant="flat">Giriş Yap</v-btn>
          <div class="text-right pt-2" @click="register=true;">Kayıt ol</div>
        </div>
        <div class="social-button">
          <!-- <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-facebook-f' style="color: #1773EA;"></i>
            </template> Facebook ile Giriş Yap
          </v-btn>
          <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-twitter' style="color: #1C96E8;"></i>
            </template> Twitter ile Giriş Yap
          </v-btn> -->
          <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="loginWGoogle" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-google' style="color: #E34133;"></i>
            </template> Google ile Giriş Yap
          </v-btn>
          <!-- <v-btn class="text-none d-flex justify-space-evenly" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-apple' style="color: #141414;"></i>
            </template> Apple ile Giriş Yap
          </v-btn> -->
        </div>
      </div>
      <div v-if="register">
        <div style="text-align: center;">
          <img src="./assets/logo.svg" alt="logo" />
          <h1>Kaza Namazı</h1>
          <h3>Ücretsiz Kayıt Ol</h3>
        </div>
        <v-divider class="pb-6"></v-divider>
        <!-- <div class="text-field">
          <span style="font-size: 14px;">Ad Soyad:</span>
          <v-text-field class="input-field" v-model="registerData.name" type="text" variant="outlined" persistent-hint></v-text-field>
        </div> -->
        <div class="text-field">
          <span style="font-size: 14px;">E-posta:</span>
          <v-text-field class="input-field" v-model="registerData.email" type="e-mail" variant="outlined" persistent-hint></v-text-field>
        </div>
        <div class="text-field">
          <span style="font-size: 14px;">Şifre:</span>
          <v-text-field class="input-field" v-model="registerData.password" type="password" variant="outlined" persistent-hint></v-text-field>
        </div>
        <div class="register-button pb-10">
          <v-btn class="text-none" @click="registerUser" block color="#FDCC34" size="x-large" variant="flat">Kayıt Ol</v-btn>
          <div class="text-right pt-2" @click="register=false;">Giriş Yap</div>
        </div>
        <div class="social-button">
          <!-- <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="loginWithFacebook" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-facebook-f' style="color: #1773EA;"></i>
            </template> Facebook ile Kayıt Ol
          </v-btn>
          <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-twitter' style="color: #1C96E8;"></i>
            </template> Twitter ile Kayıt Ol
          </v-btn> -->
          <v-btn class="text-none d-flex justify-space-evenly mb-1" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-google' style="color: #E34133;"></i>
            </template> Google ile Kayıt Ol
          </v-btn>
          <!-- <v-btn class="text-none d-flex justify-space-evenly" @click="login" block size="x-large" variant="outlined">
            <template v-slot:prepend>
              <i class='fab fa-apple' style="color: #141414;"></i>
            </template> Apple ile Kayıt Ol
          </v-btn> -->
        </div>
      </div>
    </div>
    <div v-else>
      <v-btn @click="logout" variant="flat" class="position-absolute" style="right: -15px">
        <template v-slot:prepend>
          <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" color="#444444"/>
        </template>
      </v-btn>
      <v-col v-if="isFirstLogin">
        <div class="text-center">
          <div class="header d-flex justify-center">
            <img src="./assets/logo.svg" alt="logo" width="55px" />
            <h1>Kaza Namazı</h1>
          </div>
          <v-divider class="mt-3 pb-6"></v-divider>
        </div>
        <h3 class="text-center">Kazaya Kalan Namazlarını Hesapla</h3>
        <v-tabs v-model="tab" align-tabs="center" color="deep-purple-accent-4">
          <v-tab :value="1">Tarih İle Hesapla</v-tab>
          <v-tab :value="2">Yıl İle Hesapla</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item :value="1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" style="display: flex; flex-direction: column; justify-content: space-between;">
                  <div>
                    <div class="pb-2">
                      <span style="font-size: 14px;">İlk Tarihi Seçin</span>
                      <VueDatePicker v-model="startDate" locale="tr" :flow="flow" :enable-time-picker="false" auto-apply />
                    </div>
                    <div class="pb-2">
                      <span style="font-size: 14px;">Son Tarihi Seçin</span>
                      <VueDatePicker v-model="endDate" locale="tr" :flow="flow" :enable-time-picker="false" auto-apply />
                    </div>
                    <div class="pb-2">
                      <span style="font-size: 14px;">Seçilen Vakitler</span>
                      <v-autocomplete v-model="selectedTime" :disabled="false" :items="times" color="blue-grey-lighten-2" item-title="text" item-value="value" label="Select" chips closable-chips multiple>
                        <template v-slot:chip="{ props, item }">
                          <v-chip v-bind="props" :text="item.raw.text"></v-chip>
                        </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" :title="item.raw.text"></v-list-item>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                  <div class="pb-2" style="padding-top: 150px">
                    <v-btn class="text-none" @click="submitPrayers" block color="#FDCC34" size="x-large" variant="flat">Hesapla</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
          <v-tabs-window-item :value="2">
            <v-container fluid>
              <v-row>
                <v-col cols="12" style="display: flex; flex-direction: column; justify-content: space-between;">
                  <div>
                    <div class="pb-2">
                      <span style="font-size: 14px;">Yıl Girin</span>
                      <v-text-field v-model.number="missedYear" type="number" @click:append-outer="increment" @click:prepend="decrement" :rules="inputRules" variant="outlined"></v-text-field>
                    </div>
                    <div class="pb-2">
                      <span style="font-size: 14px;">Seçilen Vakitler</span>
                      <v-autocomplete v-model="selectedTime" :disabled="false" :items="times" color="blue-grey-lighten-2" item-title="text" item-value="value" label="Select" chips closable-chips multiple>
                        <template v-slot:chip="{ props, item }">
                          <v-chip v-bind="props" :text="item.raw.text"></v-chip>
                        </template>
                        <template v-slot:item="{ props, item }">
                          <v-list-item v-bind="props" :title="item.raw.text"></v-list-item>
                        </template>
                      </v-autocomplete>
                    </div>
                  </div>
                  <div class="pb-2">
                    <v-btn class="text-none" @click="submitPrayersWithYear" block color="#FDCC34" size="x-large" variant="flat">Hesapla</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-col>
      <v-col v-else class="ga-3">
        <div class="text-center">
          <div class="header d-flex justify-center">
            <img src="./assets/logo.svg" alt="logo" width="55px" />
            <h1>Kaza Namazı</h1>
          </div>
          <v-divider class="mt-3 pb-2"></v-divider>
        </div>
        <div class="pb-3">
          <h3 class="text-center">Seçilen Tarihler</h3>
          <div class="d-flex justify-center">
          <span>Başlangıç: {{ startedDate }}</span>
          <span> - </span>
          <span>Bitiş: {{ endedDate }}</span>
        </div>
        </div>
        <h3 class="text-center">Kaza Namazlarınız</h3>
        <div>
          <div>
            <v-card v-for="(prayer, key) in sortedPrayers" :key="prayer.id" color="surface-variant" variant="outlined" class="mx-auto my-3" :order="prayer.id">
              <v-card-item>
                <div class="d-flex align-center justify-space-between">
                  <div class="text-subtitle-1 font-weight-bold mb-1">{{ times[key].text }}</div>
                  <div class="text-subtitle-1 mb-1">
                    <font-awesome-icon icon="fa-solid fa-heart-circle-minus" color="#AC0000"/> {{ prayer.missed }} Kaldı
                  </div>
                  <div class="text-subtitle-1 mb-1">
                    <font-awesome-icon icon="fa-solid fa-heart-circle-check" color="#35AC00"/> {{ prayer.completed }} Kılındı
                  </div>
                  <div>
                    <v-btn @click="submitPrayerCardButton(times[key].value)" block variant="flat" color="#FDCC34">+1 Kıldım</v-btn>
                  </div>
                </div>
              </v-card-item>
            </v-card>
          </div>
        </div>
        <div>
          <v-divider class="mt-5"></v-divider>
          <h4 class="py-3">Toplu Namaz Girişleri</h4>
          <div class="d-flex ga-3">
            <v-select class="w-50" v-model="selectedPrayer" :items="times" item-title="text" item-value="value" label="Vakit" variant="outlined"></v-select>
            <v-text-field class="w-50" v-model.number="count" type="number" @click:append-outer="increment" @click:prepend="decrement" :rules="inputRules" label="Miktar" variant="outlined"></v-text-field>
          </div>
          <v-btn class="mb-2" @click="submitPrayer" rounded="lg" size="x-large" block variant="flat" color="#444444">
            <template v-slot:prepend>
              <font-awesome-icon icon="fa-solid fa-heart-circle-plus" color="#FFFFFF"/>
            </template>
            Ekle
          </v-btn>
          <v-btn @click="submitMinusPrayer" rounded="lg" size="x-large" block variant="outlined">
            <template v-slot:prepend>
              <font-awesome-icon icon="fa-solid fa-heart-circle-minus" color="#444444"/>
            </template>
            Çıkar
          </v-btn>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: { VueDatePicker },
  data() {
    return {
      selectedTime: ['sabah', 'ogle', 'ikindi', 'aksam', 'yatsi', 'vitir'],
      flow: ['year','month', 'calendar'],
      tab: null,
      register: false,
      registerData: {
        name: '',
        email: '',
        password: ''
      },
      missedYear: null,
      startDate: '',
      endDate: '',
      selectedPrayer: null,
      count: '',
      times: [
        { value: 'sabah', text: 'Sabah' },
        { value: 'ogle', text: 'Öğle' },
        { value: 'ikindi', text: 'İkindi' },
        { value: 'aksam', text: 'Akşam' },
        { value: 'yatsi', text: 'Yatsı' },
        { value: 'vitir', text: 'Vitir'}
      ],
      inputRules: [ 
        v => !!v || "Bu alan gereklidir", 
        v => ( v > 0 ) || "1 den büyük olmalıdır",
        v => ( v <= 100 ) || "100 den küçük olmalıdır",
    ],
    };
  },
  computed: {
    ...mapState(['user', 'prayers', 'isFirstLogin', 'startedDate', 'endedDate']),
    sortedPrayers() {
      if (!this.prayers) return {};
      // JSON verilerinizi id'ye göre sıralayın
      const sorted = Object.values(this.prayers).sort((a, b) => a.id - b.id);
      // Sıralanmış verileri tekrar nesne haline getirin
      return sorted.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions(['registerEmailPassword', 'loginEmailPassword', 'loginWFacebook', 'loginWTwitter', 'loginWGoogle', 'loginWApple', 'logout', 'savePrayers', 'updatePrayer', 'updateMinusPrayer']),
    registerUser() {
      this.registerEmailPassword({ email: this.registerData.email, password: this.registerData.password });
    },
    loginUser() {
      this.loginEmailPassword({ email: this.registerData.email, password: this.registerData.password });
    },
    submitPrayers() {
      if (this.startDate && this.endDate, this.selectedTime) {
        this.savePrayers({ startDate: this.startDate, endDate: this.endDate , times: this.selectedTime});
      }
    },
    submitPrayersWithYear() {
      if (this.missedYear && this.selectedTime) {
        var {startDate, endDate} = this.updateDate(this.missedYear);
        console.log(startDate, endDate);
        this.savePrayers({ startDate: startDate, endDate: endDate , times: this.selectedTime});
      }
    },
    updateDate(missedYear) {
      const today = new Date();
      const endDate = today;
      const startDate = new Date();
      startDate.setFullYear(today.getFullYear() - missedYear);
      return {
        startDate: startDate,
        endDate: endDate
      };
    },
    submitPrayer() {
      var localCount = parseInt(this.count);
      if (localCount > 0 && this.selectedPrayer) {
        this.updatePrayer({ prayer: this.selectedPrayer, count: localCount });
      }
    },
    submitMinusPrayer() {
      var localCount = parseInt(this.count);
      if (localCount > 0 && this.selectedPrayer) {
        this.updateMinusPrayer({ prayer: this.selectedPrayer, count: localCount });
      }
    },
    submitPrayerCardButton(prayer) {
      var count = 1;
      if (count > 0 && prayer) {
        this.updatePrayer({ prayer: prayer, count: count });
      }
    },
  },
  created() {
    this.$store.dispatch('listenToAuthState');
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Quicksand:wght@300..700&display=swap');
#app {
  font-family: 'Nunito', sans-serif;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}
</style>
