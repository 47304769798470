// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAvVOeFH3R7n5wVp2lkDZxdFs7Jwzt-Zrg",
  authDomain: "kaza-namazi-app.firebaseapp.com",
  projectId: "kaza-namazi-app",
  storageBucket: "kaza-namazi-app.appspot.com",
  messagingSenderId: "362251050005",
  appId: "1:362251050005:web:6d161e67b748278d92c21c",
  measurementId: "G-Q9TGS4EF4H"
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db , analytics};
