// src/main.js
import { createApp } from 'vue';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { aliases, fa } from 'vuetify/iconsets/fa'
import {mdi} from "vuetify/lib/iconsets/mdi";
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faHeartCircleCheck,  faHeartCircleMinus, faHeartCirclePlus, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faHeartCircleCheck, faHeartCircleMinus, faHeartCirclePlus, faArrowRightFromBracket)

import App from './App.vue';
import './registerServiceWorker'
import store from './store'; // Store'u import edin

const vuetify = createVuetify({
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      mdi,
      fa
    }
  },
  components,
  directives,
})

createApp(App)
  .use(store) // Store'u kullanın
  .use(vuetify) // Vuetify'yi kullanın
  .component('font-awesome-icon', FontAwesomeIcon) // Font Awesome'yi kullanın
  .mount('#app');
