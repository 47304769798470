// src/AuthService.js
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged as firebaseOnAuthStateChanged } from 'firebase/auth';
import { auth, db } from './firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const googleProvider = new GoogleAuthProvider();

const registerWithEmailPassword = async (email, password) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    console.error("Email register error: ", error);
  }
}

const loginWithEmailPassword = async (email, password) => {
  try {
    const result = await signInWithEmailAndPassword(auth, email, password);
    return result.user;
  } catch (error) {
    console.error("Email login error: ", error);
  }
}

const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    return result.user;
  } catch (error) {
    console.error("Google login error: ", error);
  }
};

const logout = () => {
  signOut(auth);
};

const onAuthStateChanged = (callback) => {
  firebaseOnAuthStateChanged(auth, callback);
};

const saveUserPrayers = async (userId, prayers, startDate, endDate) => {
  const userRef = doc(db, 'users', userId);
  await setDoc(userRef, { prayers, startDate, endDate });
};

const getUserPrayers = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const docSnapshot = await getDoc(userRef);
  return docSnapshot.exists() ? docSnapshot.data() : null;
};

const updateUserPrayers = async (userId, prayer, count) => {
  const userRef = doc(db, 'users', userId);
  const docSnapshot = await getDoc(userRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    const prayers = data.prayers;
    if (prayers[prayer].missed < 1) {
      console.error('Prayer count cannot be less than 0');
      return null;
    }
    if ( prayers[prayer].missed - count < 0) {
      console.error('Prayer count cannot be less than 0');
      return null;
    }
    prayers[prayer].missed -= count;
    prayers[prayer].completed += count;
    await updateDoc(userRef, { prayers });
    return { ...data, prayers };
  }
  return null;
};

const updateMinusPrayer = async (userId, prayer, count) => {
  const userRef = doc(db, 'users', userId);
  const docSnapshot = await getDoc(userRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();
    const prayers = data.prayers;
    if (prayers[prayer].completed < 1) {
      console.error('Prayer count cannot be less than 0');
      return null;
    }
    if ( prayers[prayer].completed - count < 0) {
      console.error('Prayer count cannot be less than 0');
      return null;
    }
    prayers[prayer].missed += count;
    prayers[prayer].completed -= count;
    await updateDoc(userRef, { prayers });
    return { ...data, prayers };
  }
  return null;
};

export { registerWithEmailPassword, loginWithEmailPassword, loginWithGoogle, logout, onAuthStateChanged, saveUserPrayers, getUserPrayers, updateUserPrayers, updateMinusPrayer };
