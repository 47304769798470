// src/store/index.js
import { createStore } from 'vuex';
import { registerWithEmailPassword, loginWithEmailPassword, loginWithGoogle, logout, onAuthStateChanged, saveUserPrayers, getUserPrayers, updateUserPrayers, updateMinusPrayer } from '../AuthService';
import { calculateMissedPrayers, dateFormater, timestampToDate } from '../utils';

export default createStore({
  state: {
    user: null,
    prayers: null,
    startedDate: '',
    endedDate: '',
    isFirstLogin: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setPrayers(state, prayers) {
      state.prayers = prayers;
    },
    setFirstLogin(state, isFirstLogin) {
      state.isFirstLogin = isFirstLogin;
    },
    setStartDate(state, startDate) {
      state.startedDate = startDate;
    },
    setEndDate(state, endDate) {
      state.endedDate = endDate;
    },
  },
  actions: {
    async registerEmailPassword({ commit }, { email, password }) {
      const user = await registerWithEmailPassword(email, password);
      commit('setUser', user);
      if (user) {
        commit('setFirstLogin', true);
      }
    },
    async loginEmailPassword({ commit }, { email, password }) {
      const user = await loginWithEmailPassword(email, password);
      commit('setUser', user);
      if (user) {
        const data = await getUserPrayers(user.uid);
        if (!data) {
          commit('setFirstLogin', true);
        } else {
          commit('setPrayers', data.prayers);
          commit('setStartDate', data.startDate);
          commit('setEndDate', data.endDate);
        }
      }
    },
    async loginWGoogle({ commit }) {
      const user = await loginWithGoogle();
      commit('setUser', user);
      if (user) {
        const data = await getUserPrayers(user.uid);
        if (!data) {
          commit('setFirstLogin', true);
        } else {
          const sDate = dateFormater(timestampToDate(data.startDate));
          const eDate = dateFormater(timestampToDate(data.endDate));
          commit('setPrayers', data.prayers);
          commit('setStartDate', sDate);
          commit('setEndDate', eDate);
        }
      }
    },
    async savePrayers({ state, commit }, { startDate, endDate, times}) {
      const prayers = calculateMissedPrayers(startDate, endDate, times);
      const sDate = dateFormater(startDate);
      const eDate = dateFormater(endDate);
      await saveUserPrayers(state.user.uid, prayers, startDate, endDate);
      commit('setPrayers', prayers);
      commit('setStartDate', sDate);
      commit('setEndDate', eDate);
      commit('setFirstLogin', false);
    },
    async updatePrayer({ state, commit }, { prayer, count }) {
      const updatedData = await updateUserPrayers(state.user.uid, prayer, count);
      if (updatedData) {
        commit('setPrayers', updatedData.prayers);
      }
    },
    async updateMinusPrayer({ state, commit }, { prayer, count }) {
      const updatedData = await updateMinusPrayer(state.user.uid, prayer, count);
      if (updatedData) {
        commit('setPrayers', updatedData.prayers);
      }
    },

    async logout({ commit }) {
      await logout();
      commit('setUser', null);
      commit('setPrayers', null);
      commit('setStartDate', '');
      commit('setEndDate', '');
    },
    listenToAuthState({ commit }) {
      onAuthStateChanged(async (user) => {
        if (user) {
          commit('setUser', user);
          const data = await getUserPrayers(user.uid);
          if (data) {
            const sDate = dateFormater(timestampToDate(data.startDate));
            const eDate = dateFormater(timestampToDate(data.endDate));
            commit('setPrayers', data.prayers);
            commit('setStartDate', sDate);
            commit('setEndDate', eDate);
            commit('setFirstLogin', false);
          } else {
            commit('setFirstLogin', true);
          }
        } else {
          commit('setUser', null);
          commit('setPrayers', null);
          commit('setStartDate', '');
          commit('setEndDate', '');
          commit('setFirstLogin', false);
        }
      });
    },
  },
  modules: {},
});
